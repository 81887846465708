import React, { useCallback, useMemo, useState } from 'react';
import IntegrationAPIGen, {
  IntegrationPointResponse
} from 'ecto-common/lib/API/IntegrationAPIGen';
import { useParams } from 'react-router-dom';
import DataTableLoadMoreFooter from 'ecto-common/lib/DataTable/DataTableLoadMoreFooter';
import ErrorNotice from 'ecto-common/lib/Notice/ErrorNotice';
import T from 'ecto-common/lib/lang/Language';
import Icons from 'ecto-common/lib/Icons/Icons';
import _ from 'lodash';
import DataTable from 'ecto-common/lib/DataTable/DataTable';
import useDialogState from 'ecto-common/lib/hooks/useDialogState';
import AddNodeToIntegrationPointModal from 'js/components/Integrations/AddNodeToIntegrationPointModal';
import { NodeParams } from 'ecto-common/lib/utils/locationPathUtils';
import { standardColumns } from 'ecto-common/lib/utils/dataTableUtils';
import { DataTableColumnProps } from 'ecto-common/lib/DataTable/DataTable';
import ConfirmDeleteDialog from 'ecto-common/lib/ConfirmDeleteDialog/ConfirmDeleteDialog';
import IntegrationAdminAPIGen from 'ecto-common/lib/API/IntegrationAdminAPIGen';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import { useQueryClient } from '@tanstack/react-query';
import Button from 'ecto-common/lib/Button/Button';

const columns: DataTableColumnProps<IntegrationPointResponse>[] = [
  {
    dataKey: 'name',
    label: T.admin.integrations.integrationpointname
  }
];

type EditIntegrationPointsForNodeProps = {
  nodeId: string;
};

const EditIntegrationPointsForNode = ({
  nodeId
}: EditIntegrationPointsForNodeProps) => {
  const [
    isAddNodeToPointDialogOpen,
    showAddNodeToPointDialog,
    hideAddNodeToPointDialog
  ] = useDialogState('show-edit-integration-points-for-node');
  const [pointToDelete, setPointToDelete] =
    useState<IntegrationPointResponse>(null);
  const params = useParams<NodeParams>();
  const queryClient = useQueryClient();

  const { mutate: deletePointFromNode, isPending: isDeletingPointFromNode } =
    IntegrationAdminAPIGen.IntegrationPoints.deleteNodeFromIntegrationPoint.useMutation(
      {
        onSuccess: () => {
          setPointToDelete(null);
          queryClient.invalidateQueries();
        },
        onError: () => {
          toastStore.addErrorToast(T.common.unknownerror);
        }
      }
    );

  const {
    data,
    isLoading,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    error
  } =
    IntegrationAPIGen.IntegrationPoints.listIntegrationPoints.useInfiniteQuery(
      {
        $filter: "'" + params.nodeId + "' in nodeIds",
        $orderby: 'name asc'
      },
      {
        retry: false
      }
    );

  const allColumns = useMemo(() => {
    return [
      ...columns,
      ...standardColumns({
        onDelete: (item: IntegrationPointResponse) => setPointToDelete(item)
      })
    ];
  }, []);

  const allItems = useMemo(() => {
    return _.flatMap(data?.pages, (page) => page.items);
  }, [data]);

  const _deletePointFromNode = useCallback(() => {
    deletePointFromNode({
      nodeId,
      pointId: pointToDelete?.id
    });
  }, [deletePointFromNode, nodeId, pointToDelete?.id]);

  return (
    <div style={{ maxWidth: 700 }}>
      {error != null && <ErrorNotice> {T.common.unknownerror} </ErrorNotice>}
      <DataTable<IntegrationPointResponse>
        columns={allColumns}
        data={allItems}
        isLoading={isLoading}
      />
      <DataTableLoadMoreFooter
        isFetchingNextPage={isFetchingNextPage}
        fetchNextPage={fetchNextPage}
        hasNextPage={hasNextPage}
      />
      <Button onClick={showAddNodeToPointDialog}>
        <Icons.Add /> {T.admin.integrations.addtopoint}{' '}
      </Button>
      <AddNodeToIntegrationPointModal
        nodeId={params.nodeId}
        isOpen={isAddNodeToPointDialogOpen}
        onModalClose={hideAddNodeToPointDialog}
      />
      <ConfirmDeleteDialog
        isOpen={pointToDelete != null}
        onModalClose={() => setPointToDelete(null)}
        itemName={pointToDelete?.name}
        isLoading={isDeletingPointFromNode}
        onDelete={_deletePointFromNode}
      />
    </div>
  );
};

EditIntegrationPointsForNode.propTypes = {};

export default React.memo(EditIntegrationPointsForNode);
