import React, {
  useCallback,
  useMemo,
  useEffect,
  useRef,
  useContext,
  SetStateAction,
  Dispatch
} from 'react';
import _ from 'lodash';

import TextInput from 'ecto-common/lib/TextInput/TextInput';
import {
  getEquipmentName,
  isEquipmentOfType
} from 'ecto-common/lib/utils/equipmentTypeUtils';
import Select, { GenericSelectOption } from 'ecto-common/lib/Select/Select';
import T from 'ecto-common/lib/lang/Language';
import zindex from 'ecto-common/lib/styles/variables/zindex';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';
import { KeyValueFixedSelectableInput } from 'ecto-common/lib/KeyValueInput/KeyValueFixedSelectableInput';
import { KeyValueGeneric } from 'ecto-common/lib/KeyValueInput/KeyValueGeneric';
import SelectDeviceDialog from 'js/components/EditLocation/SelectDeviceDialog';
import { AddEquipmentFormActions } from 'js/modules/addEquipmentForm/addEquipmentForm';
import { AddEquipmentState } from 'js/modules/addEquipmentForm/addEquipmentForm';
import pageStyles from 'js/components/PageStyle.module.css';
import { getValidAlarmSignalGroupTemplates } from 'js/utils/alarmSignalGroupTemplateUtils';
import {
  ToolEnumTranslations,
  getToolTypeEnumResourcesByUserAccess
} from 'js/components/ManageEquipment/EditEquipment/toolTypes';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { useAdminSelector, useAdminDispatch } from 'js/reducers/storeAdmin';
import { NodeV2ResponseModel, ToolType } from 'ecto-common/lib/API/APIGen';
import { KeyValueColumn } from 'ecto-common/lib/KeyValueInput/KeyValueColumn';
import { CSSObjectWithLabel } from 'react-select';
import { useNode } from 'ecto-common/lib/hooks/useCurrentNode';

interface NewEquipmentProps {
  devices?: NodeV2ResponseModel[];
  selectedEnergyManager: SelectedEnergyManagerState;
  setSelectedEnergyManager: Dispatch<
    SetStateAction<SelectedEnergyManagerState>
  >;
}

export type SelectedEnergyManagerState = {
  energyManager: NodeV2ResponseModel;
};

const NewEquipment = ({
  devices,
  selectedEnergyManager,
  setSelectedEnergyManager
}: NewEquipmentProps) => {
  const dispatch = useAdminDispatch();

  const addState = useAdminSelector((state) => state.addEquipmentForm.addState);
  const alarmSignalGroupTemplateId = useAdminSelector(
    (state) => state.addEquipmentForm.alarmSignalGroupTemplateId
  );
  const description = useAdminSelector(
    (state) => state.addEquipmentForm.description
  );
  const deviceEquipmentId = useAdminSelector(
    (state) => state.addEquipmentForm.deviceEquipmentId
  );
  const dialogIsOpen = useAdminSelector(
    (state) => state.addEquipmentForm.dialogIsOpen
  );
  const equipmentTypeId = useAdminSelector(
    (state) => state.addEquipmentForm.equipmentTypeId
  );
  const equipmentTypes = useAdminSelector(
    (state) => state.general.equipmentTypes
  );
  const forceAddEnergyManager = useAdminSelector(
    (state) => state.addEquipmentForm.forceAddEnergyManager
  );
  const name = useAdminSelector((state) => state.addEquipmentForm.name);
  const selectedToolTypeIds = useAdminSelector(
    (state) => state.addEquipmentForm.selectedToolTypeIds
  );
  const showDeviceSelector = useAdminSelector(
    (state) => state.addEquipmentForm.showDeviceSelector
  );
  const signalTemplates = useAdminSelector(
    (state) => state.admin.signalTemplates
  );
  const targetNodeId = useAdminSelector(
    (state) => state.addEquipmentForm.targetNodeId
  );
  const toolTypes = useAdminSelector((state) => state.general.enums.toolTypes);

  const prevAddStateRef = useRef(undefined);

  useEffect(() => {
    if (
      addState !== prevAddStateRef.current &&
      addState === AddEquipmentState.ADDED
    ) {
      toastStore.addSuccessToast(T.admin.requests.addequipment.success);

      prevAddStateRef.current = addState;
    }

    return () => {
      if (prevAddStateRef.current) {
        dispatch(AddEquipmentFormActions.resetAddState());
      }
    };
  }, [addState, dispatch]);

  const handleSelectedEnergyManagerChanged = useCallback(
    (newEnergyManager: NodeV2ResponseModel) => {
      setSelectedEnergyManager(
        newEnergyManager ? { energyManager: newEnergyManager } : null
      );
    },
    [setSelectedEnergyManager]
  );

  // Clear selected energy manager when dialog closes
  useEffect(() => {
    if (!dialogIsOpen) {
      setSelectedEnergyManager(null);
    }
  }, [dialogIsOpen, setSelectedEnergyManager]);

  const isEnergyManager = useMemo(
    () => isEquipmentOfType(equipmentTypeId, 'EnergyManager', equipmentTypes),
    [equipmentTypeId, equipmentTypes]
  );
  const alarmTemplateOptions = useMemo(
    () =>
      getValidAlarmSignalGroupTemplates(
        signalTemplates.alarmSignalTemplates,
        equipmentTypeId
      ),
    [equipmentTypeId, signalTemplates.alarmSignalTemplates]
  );

  const selectedAlarmTemplateOption = useMemo(
    () =>
      _.find(alarmTemplateOptions, { value: alarmSignalGroupTemplateId || '' }),
    [alarmSignalGroupTemplateId, alarmTemplateOptions]
  );

  const { tenantResources } = useContext(TenantContext);
  const toolsFilteredByUserAccess = useMemo(
    () => getToolTypeEnumResourcesByUserAccess(toolTypes, tenantResources),
    [toolTypes, tenantResources]
  );

  const toolOptions: GenericSelectOption<ToolType>[] = useMemo(
    () =>
      _.map(toolsFilteredByUserAccess, (toolTemplate: ToolType) => ({
        value: toolTemplate,
        label: ToolEnumTranslations[toolTemplate] ?? toolTemplate
      })),
    [toolsFilteredByUserAccess]
  );

  const selectedToolOptions = useMemo(
    () =>
      _.filter(toolOptions, (option) =>
        _.includes(selectedToolTypeIds, option.value)
      ),
    [selectedToolTypeIds, toolOptions]
  );

  const equipmentOptions = useMemo(
    () =>
      _.map(equipmentTypes, (type) => ({
        value: type.equipmentTypeId,
        label: getEquipmentName(type.equipmentTypeId, equipmentTypes)
      })),
    [equipmentTypes]
  );

  const selectedEquipmentOption = useMemo(
    () => _.find(equipmentOptions, { value: equipmentTypeId }),
    [equipmentOptions, equipmentTypeId]
  );

  const deviceOptions = useMemo(
    () =>
      _.map(devices, (device) => ({
        value: device.nodeId,
        label: device.name
      })),
    [devices]
  );

  const selectedDeviceOption = useMemo(
    () => _.find(deviceOptions, { value: deviceEquipmentId }),
    [deviceEquipmentId, deviceOptions]
  );

  const { node } = useNode(targetNodeId);
  const { node: parentNode } = useNode(node?.parentId);

  const { node: existingEnergyManagerNode } = useNode(
    selectedEnergyManager?.energyManager?.nodeId
  );

  const portalProps = {
    styles: {
      menuPortal: (base: CSSObjectWithLabel) => ({
        ...base,
        zIndex: zindex.modalOverlayZIndex
      })
    },
    menuPortalTarget: document.body
  };

  const onSetEquipmentTypeId = useCallback(
    (event: GenericSelectOption) => {
      dispatch(AddEquipmentFormActions.setEquipmentTypeId(event.value));
    },
    [dispatch]
  );

  const onSetDeviceEquipmentId = useCallback(
    (event: GenericSelectOption) => {
      dispatch(AddEquipmentFormActions.setDeviceEquipmentId(event.value));
    },
    [dispatch]
  );

  const onSetShowDeviceSelector = useCallback(() => {
    dispatch(AddEquipmentFormActions.setShowDeviceSelector(true));
  }, [dispatch]);

  const onSetShowDeviceClear = useCallback(() => {
    setSelectedEnergyManager(null);
  }, [setSelectedEnergyManager]);

  const onNameChange: React.ChangeEventHandler<HTMLInputElement> = useCallback(
    (event) => {
      dispatch(AddEquipmentFormActions.setName(event.target.value));
    },
    [dispatch]
  );

  const onDescriptionChange: React.ChangeEventHandler<HTMLInputElement> =
    useCallback(
      (event) => {
        dispatch(AddEquipmentFormActions.setDescription(event.target.value));
      },
      [dispatch]
    );

  const onSetAlarmSignalGroupTemplateId = useCallback(
    (event: GenericSelectOption) => {
      dispatch(
        AddEquipmentFormActions.setAlarmSignalGroupTemplateId(
          event?.value ?? null
        )
      );
    },
    [dispatch]
  );

  const onSetSelectedToolTypeIds = useCallback(
    (event: GenericSelectOption<ToolType>[]) => {
      dispatch(
        AddEquipmentFormActions.setSelectedToolTypeIds(_.map(event, 'value'))
      );
    },
    [dispatch]
  );

  const onHideDeviceSelector = useCallback(() => {
    dispatch(AddEquipmentFormActions.setShowDeviceSelector(false));
  }, [dispatch]);

  const onConfirmDevice = useCallback(() => {
    dispatch(AddEquipmentFormActions.setShowDeviceSelector(false));
  }, [dispatch]);

  return (
    <>
      <KeyValueColumn>
        <KeyValueGeneric keyText={T.admin.equipment.type}>
          <Select
            options={equipmentOptions}
            isDisabled={forceAddEnergyManager}
            value={selectedEquipmentOption}
            onChange={onSetEquipmentTypeId}
            {...portalProps}
          />
        </KeyValueGeneric>

        {!isEnergyManager && (
          <KeyValueGeneric keyText={T.admin.equipment.deviceparent}>
            <Select
              options={deviceOptions}
              value={selectedDeviceOption}
              onChange={onSetDeviceEquipmentId}
              {...portalProps}
            />
          </KeyValueGeneric>
        )}

        {isEnergyManager && (
          <KeyValueFixedSelectableInput
            keyText={T.admin.equipment.chooseexistingem}
            placeholder={T.admin.equipment.createnewem}
            value={
              existingEnergyManagerNode &&
              T.format(
                T.admin.equipment.energymanagerfromformat,
                existingEnergyManagerNode.name
              ).join('')
            }
            onClick={onSetShowDeviceSelector}
            isClearable
            onClear={onSetShowDeviceClear}
          />
        )}

        <KeyValueGeneric keyText={T.admin.equipment.name}>
          <TextInput
            autoFocus
            placeholder={T.admin.equipment.nameplaceholder}
            value={name}
            autoComplete="off"
            spellCheck="false"
            onChange={onNameChange}
          />
        </KeyValueGeneric>

        <KeyValueGeneric keyText={T.admin.equipment.description}>
          <TextInput
            placeholder={T.admin.equipment.description}
            value={description}
            autoComplete="off"
            spellCheck="false"
            onChange={onDescriptionChange}
          />
        </KeyValueGeneric>

        <KeyValueGeneric keyText={T.admin.equipment.alarmtemplate}>
          <Select
            className={pageStyles.smallSelector}
            value={selectedAlarmTemplateOption}
            placeholder={T.admin.equipment.none}
            onChange={onSetAlarmSignalGroupTemplateId}
            maxMenuHeight={175}
            isClearable
            disabled={isEnergyManager}
            options={alarmTemplateOptions}
            {...portalProps}
          />
        </KeyValueGeneric>

        {!isEnergyManager && (
          <KeyValueGeneric keyText={T.admin.equipment.tools}>
            <Select<GenericSelectOption<ToolType>, true>
              className={pageStyles.smallSelector}
              value={selectedToolOptions}
              isMulti
              onChange={onSetSelectedToolTypeIds}
              maxMenuHeight={175}
              options={toolOptions}
              {...portalProps}
            />
          </KeyValueGeneric>
        )}
      </KeyValueColumn>

      <SelectDeviceDialog
        onModalClose={onHideDeviceSelector}
        isOpen={showDeviceSelector}
        initialNode={parentNode}
        selectedEnergyManagerEquipmentId={
          selectedEnergyManager?.energyManager?.nodeId
        }
        onChangedSelectedEnergyManager={handleSelectedEnergyManagerChanged}
        onConfirmClick={onConfirmDevice}
      />
    </>
  );
};

export default NewEquipment;
