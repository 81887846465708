import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import _ from 'lodash';
import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import APIGen, { NodeTraitResponseModel } from 'ecto-common/lib/API/APIGen';
import DataTable, {
  DataTableColumnProps
} from 'ecto-common/lib/DataTable/DataTable';
import T from 'ecto-common/lib/lang/Language';
import { useSearchParamState } from 'ecto-common/lib/hooks/useDialogState';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';

import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import Icons from 'ecto-common/lib/Icons/Icons';
import { ModelDefinition } from 'ecto-common/lib/ModelForm/ModelPropType';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import ModelForm from 'ecto-common/lib/ModelForm/ModelForm';
import DataTableFooter from 'ecto-common/lib/DataTable/DataTableFooter';
import AddButton from 'ecto-common/lib/Button/AddButton';
import UUID from 'uuidjs';
import { useQueryClient } from '@tanstack/react-query';
import AdminPage from 'js/components/AdminPage';
import { Y } from 'ecto-common/lib/lang/NodeLocalization';
import { BatchedGetNodesQueryKey } from 'ecto-common/lib/hooks/useCurrentNode';
import Checkbox from 'ecto-common/lib/Checkbox/Checkbox';

const NodeTraitsList = () => {
  const traitsQuery = APIGen.NodesV2.listNodeTraits.useQuery();

  const columns = useMemo<
    DataTableColumnProps<NodeTraitResponseModel>[]
  >(() => {
    return [
      {
        dataKey: 'isGlobal',
        label: Y.nodes.global,
        minWidth: 70,
        maxWidth: 70,
        align: 'center',
        dataFormatter: (value: boolean) => <Checkbox checked={value} disabled />
      },
      {
        dataKey: 'isLocked',
        label: Y.nodes.locked,
        minWidth: 80,
        maxWidth: 80,
        align: 'center',
        dataFormatter: (value) => (value ? <Icons.Lock /> : null)
      },
      {
        dataKey: 'name',
        label: T.common.name,
        linkColumn: true
      }
    ];
  }, []);

  const [traitDetailId, setTraitDetailId] = useSearchParamState(
    'trait-id',
    null
  );

  const propertiesQuery = APIGen.NodesV2.getNodeProperties.useQuery();

  const propertyOptions = useMemo(() => {
    return _.map(propertiesQuery.data, (property) => {
      return {
        label: property.name,
        value: property.id
      };
    });
  }, [propertiesQuery.data]);

  const onClickRow = (trait: NodeTraitResponseModel) => {
    setTraitDetailId(trait.id);
  };

  const specificTraitQuery = APIGen.NodesV2.getNodeTraitsByIds.useQuery(
    {
      traitIds: [traitDetailId]
    },
    {
      enabled: !!traitDetailId
    }
  );

  const [editTrait, setEditTrait] = useState<NodeTraitResponseModel | null>(
    null
  );
  const specificTrait = _.head(specificTraitQuery.data);

  if (editTrait == null && specificTrait != null) {
    setEditTrait(_.cloneDeep(specificTrait));
  }
  const isEditingTrait = editTrait?.id === specificTrait?.id;

  const queryClient = useQueryClient();
  const { contextSettings } = useContext(TenantContext);
  const saveMutation = APIGen.NodesV2.addOrUpdateNodeTraits.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: APIGen.NodesV2.getNodeTraitsByIds.path(contextSettings)
      });
      queryClient.invalidateQueries({
        queryKey: APIGen.NodesV2.listNodeTraits.path(contextSettings)
      });

      queryClient.invalidateQueries({
        queryKey: APIGen.NodesV2.getNodesByIds.path(contextSettings)
      });
      queryClient.invalidateQueries({
        queryKey: [BatchedGetNodesQueryKey]
      });

      setEditTrait(null);
      setTraitDetailId(null);
    }
  });
  const save = useCallback(() => {
    saveMutation.mutate({
      nodeTraits: [editTrait]
    });
  }, [editTrait, saveMutation]);

  const addTrait = useCallback(() => {
    setEditTrait({
      id: UUID.generate(),
      name: 'New trait',
      isGlobal: false,
      isLocked: false,
      propertyIds: []
    });
  }, []);

  const traitModels: ModelDefinition<NodeTraitResponseModel>[] = useMemo(
    () => [
      {
        key: (input) => input.name,
        label: T.common.name,
        modelType: ModelType.TEXT
      },
      {
        key: (input) => input.propertyIds,
        label: Y.nodes.nodeproperties,
        modelType: ModelType.OPTIONS,
        isMultiOption: true,
        options: propertyOptions,
        isHorizontal: true
      }
    ],
    [propertyOptions]
  );

  const sortedData = useMemo(() => {
    return _.orderBy(traitsQuery.data, ['isGlobal', 'name'], ['desc', 'asc']);
  }, [traitsQuery.data]);

  return (
    <>
      <DataTable
        columns={columns}
        data={sortedData}
        isLoading={traitsQuery.isLoading}
        onClickRow={onClickRow}
      />
      <DataTableFooter alignRight>
        <AddButton onClick={addTrait}>{Y.traits.addtrait}</AddButton>
      </DataTableFooter>

      <ActionModal
        isOpen={traitDetailId != null || editTrait != null}
        onModalClose={() => {
          setEditTrait(null);
          setTraitDetailId(null);
        }}
        title={isEditingTrait ? Y.traits.edittrait : Y.traits.addtrait}
        onConfirmClick={save}
        disableActionButton={editTrait?.isLocked}
        headerIcon={Icons.Edit}
        isLoading={specificTraitQuery.isLoading || saveMutation.isPending}
        actionText={
          <>
            <Icons.Save />
            {T.common.save}
          </>
        }
      >
        {editTrait && (
          <>
            <ModelForm
              input={editTrait}
              setInput={setEditTrait}
              models={traitModels}
            />
          </>
        )}
      </ActionModal>
    </>
  );
};

const NodeTraits = () => {
  useEffect(() => {
    document.title = Y.nodes.nodetraits;
  }, []);

  return (
    <AdminPage
      content={
        <ToolbarContentPage
          showLocationPicker={false}
          title={Y.nodes.nodetraits}
        >
          <NodeTraitsList />
        </ToolbarContentPage>
      }
    />
  );
};

export default React.memo(NodeTraits);
