import React, {
  useCallback,
  useState,
  useEffect,
  useRef,
  useContext
} from 'react';
import classNames from 'classnames';
import _ from 'lodash';

import T from 'ecto-common/lib/lang/Language';
import Modal from 'ecto-common/lib/Modal/Modal';
import ModalBody from 'ecto-common/lib/Modal/ModalBody';
import ModalFooter from 'ecto-common/lib/Modal/ModalFooter';
import ModalHeader from 'ecto-common/lib/Modal/ModalHeader';
import GreyButton from 'ecto-common/lib/Button/GreyButton';
import LocalizedButtons from 'ecto-common/lib/Button/LocalizedButtons';
import Icons from 'ecto-common/lib/Icons/Icons';
import { toastStore } from 'ecto-common/lib/Toast/ToastContainer';

import { CreateNodeActions } from 'js/modules/createNodeForm/createNodeForm';
import { CreatingNodeState } from 'js/modules/createNodeForm/createNodeForm';
import SelectMeteorologyPoint from 'js/components/EditLocation/SelectMeteorologyPoint';
import { translationsForNodeState } from 'js/components/EditLocation/translations';
import styles from 'js/components/EditLocation/CreateLocationDialog.module.css';
import { useAdminSelector, useAdminDispatch } from 'js/reducers/storeAdmin';
import {
  GeographicalPointResponseModel,
  NodeV2ResponseModel
} from 'ecto-common/lib/API/APIGen';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import {
  nodeIsBuilding,
  nodeIsSpace
} from 'ecto-common/lib/hooks/useCurrentNode';
import LoadingContainer from 'ecto-common/lib/LoadingContainer/LoadingContainer';

interface EditMeteorologyPointDialogProps {
  onModalClose: () => void;
  isOpen: boolean;
  selectedLocation?: NodeV2ResponseModel;
  useForEditingExisting?: boolean;
}

function EditMeteorologyPointForNodeContent({
  selectedLocation,
  useForEditingExisting,
  padContent
}: {
  selectedLocation: NodeV2ResponseModel;
  useForEditingExisting?: boolean;
  padContent?: boolean;
}) {
  const dispatch = useAdminDispatch();
  const createNodeState = useAdminSelector(
    (state) => state.createNodeForm.createNodeState
  );
  const weatherPoint = useAdminSelector(
    (state) => state.createNodeForm.weatherPoint
  );
  const editWeatherPoint = useAdminSelector(
    (state) => state.createNodeForm.editWeatherPoint
  );

  const [checkedIds, setCheckedIds] = useState<string[]>(
    _.compact([useForEditingExisting ? editWeatherPoint?.id : weatherPoint?.id])
  );
  const { nodeId } = selectedLocation;
  const prevCreateNodeStateRef = useRef(null);

  // Node was updated, close dialog
  useEffect(() => {
    if (prevCreateNodeStateRef.current !== createNodeState) {
      if (createNodeState === CreatingNodeState.NODE_UPDATED) {
        if (nodeIsBuilding(selectedLocation)) {
          toastStore.addSuccessToast(
            <span>
              {T.format(
                T.admin.updatedbuilding.success.noerrorsformat,
                selectedLocation.name
              )}
            </span>
          );
        } else if (nodeIsSpace(selectedLocation)) {
          toastStore.addSuccessToast(
            <span>
              {T.format(
                T.admin.updatedsite.success.noerrorsformat,
                selectedLocation.name
              )}
            </span>
          );
        }
      } else if (createNodeState === CreatingNodeState.ERROR) {
        toastStore.addErrorToast(T.common.unknownerror);
      }

      prevCreateNodeStateRef.current = createNodeState;
    }
  }, [createNodeState, selectedLocation, selectedLocation.name]);

  const updateWeatherPoint = useCallback(
    (
      point: GeographicalPointResponseModel,
      previousPoints: GeographicalPointResponseModel[]
    ) => {
      if (useForEditingExisting) {
        dispatch(CreateNodeActions.editWeatherPoint(point, previousPoints));
      } else {
        dispatch(CreateNodeActions.selectWeatherPoint(point, []));
      }
    },
    [dispatch, useForEditingExisting]
  );

  const onPointsLoaded = useCallback(
    (points: GeographicalPointResponseModel[]) => {
      /**
     * If we are editing an existing item, this should be cleared every time the dialog is reopened.
     * This is because you commit the edit (or cancel it) whenever you close, so we can always retrieve
     * the current state.

     * We should not do this when editing a new item however, since then the value is not stored in the backend
     * after closing the dialog (plus we don't have a location to filter against).
     */
      if (useForEditingExisting) {
        setCheckedIds(
          _.map(
            _.filter(points, (point) =>
              _.includes(point?.nodeIds, selectedLocation.nodeId)
            ),
            'id'
          )
        );
      }
    },
    [selectedLocation.nodeId, useForEditingExisting]
  );

  return (
    <SelectMeteorologyPoint
      currentNodeId={useForEditingExisting ? nodeId : undefined}
      onSelectedItem={updateWeatherPoint}
      checkedIds={checkedIds}
      setCheckedIds={setCheckedIds}
      onPointsLoaded={onPointsLoaded}
      padContent={padContent}
    />
  );
}

export function EditMeteorologyPointForNode({
  selectedLocation
}: {
  selectedLocation: NodeV2ResponseModel;
}) {
  const dispatch = useAdminDispatch();
  const { contextSettings } = useContext(TenantContext);

  const performUpdate = useCallback(() => {
    dispatch(
      CreateNodeActions.confirmEditWeatherPoint(
        contextSettings,
        selectedLocation.nodeId
      )
    );
  }, [dispatch, selectedLocation.nodeId, contextSettings]);

  const createNodeState = useAdminSelector(
    (state) => state.createNodeForm.createNodeState
  );

  const loadingText = translationsForNodeState[createNodeState];
  const isLoading = loadingText != null;

  return (
    <div className={styles.editMeteorologyPointForNode}>
      <LoadingContainer isLoading={isLoading}>
        <EditMeteorologyPointForNodeContent
          selectedLocation={selectedLocation}
          useForEditingExisting
        />
        <LocalizedButtons.Save disabled={isLoading} onClick={performUpdate} />
      </LoadingContainer>
    </div>
  );
}

const EditMeteorologyPointDialog = ({
  onModalClose,
  isOpen,
  selectedLocation,
  useForEditingExisting
}: EditMeteorologyPointDialogProps) => {
  const dispatch = useAdminDispatch();
  const createNodeState = useAdminSelector(
    (state) => state.createNodeForm.createNodeState
  );

  const { contextSettings } = useContext(TenantContext);
  const loadingText = translationsForNodeState[createNodeState];
  const isLoading = loadingText != null;

  const prevCreateNodeStateRef = useRef(null);

  // Node was updated, close dialog
  useEffect(() => {
    if (prevCreateNodeStateRef.current !== createNodeState) {
      if (createNodeState === CreatingNodeState.NODE_UPDATED) {
        onModalClose?.();
      }
      prevCreateNodeStateRef.current = createNodeState;
    }
  }, [createNodeState, onModalClose, selectedLocation, selectedLocation.name]);

  const performUpdate = useCallback(() => {
    if (useForEditingExisting) {
      dispatch(
        CreateNodeActions.confirmEditWeatherPoint(
          contextSettings,
          selectedLocation.nodeId
        )
      );
    } else {
      dispatch(CreateNodeActions.showWeatherPointDialog(false));
    }
  }, [
    dispatch,
    selectedLocation.nodeId,
    contextSettings,
    useForEditingExisting
  ]);

  return (
    <Modal
      className={styles.weatherDialog}
      onModalClose={onModalClose}
      isOpen={isOpen}
      disableClose={isLoading}
    >
      <ModalHeader titleIcon={Icons.Site}>
        {useForEditingExisting
          ? T.admin.editlocation.editmeteorology
          : T.admin.createlocation.configureweather.title}
      </ModalHeader>

      <ModalBody
        loading={isLoading}
        loadingText={loadingText}
        className={classNames(styles.settingBody, styles.noPaddingBody)}
      >
        <EditMeteorologyPointForNodeContent
          selectedLocation={selectedLocation}
          useForEditingExisting={useForEditingExisting}
          padContent
        />
      </ModalBody>

      <ModalFooter>
        <LocalizedButtons.Save disabled={isLoading} onClick={performUpdate} />

        <GreyButton disabled={isLoading} onClick={onModalClose}>
          {T.common.cancel}
        </GreyButton>
      </ModalFooter>
    </Modal>
  );
};

export default React.memo(EditMeteorologyPointDialog);
