import { NodeParams } from 'ecto-common/lib/utils/locationPathUtils';
import { LocationRoute } from 'js/utils/routeConstants';
import { matchPath } from 'react-router-dom';

export const getEquipmentPageUrl = (
  tenantId: string,
  nodeId: string,
  itemId: string
) => {
  let url = `/${tenantId}/home/${nodeId}/dashboard/`;

  if (itemId != null) {
    url += itemId;
  }

  return url;
};

export const adminHomeUrlBuilder = (
  tenantId: string,
  newNodeId: string,
  equipmentId: string
) => {
  const params = matchPath<NodeParams, typeof LocationRoute.path>(
    LocationRoute.path,
    window.location.pathname
  ).params;

  if (equipmentId != null) {
    return `/${tenantId}/home/${newNodeId}/dashboard/${equipmentId}/${params.subPage ?? 'details'}`;
  }

  return `/${tenantId}/home/${newNodeId}/dashboard/`;
};
