import React, { useContext } from 'react';
import Icons from 'ecto-common/lib/Icons/Icons';
import { InstantiateBuildingActions } from 'js/modules/instantiateEmptyBuilding/instantiateEmptyBuilding';
import T from 'ecto-common/lib/lang/Language';
import { translationsForTemplateState } from 'js/components/InitEquipmentTemplate/translations';
import ConfigureTemplateEquipmentDialog from 'js/components/InitEquipmentTemplate/ConfigureTemplateEquipmentDialog';
import { useAdminSelector, useAdminDispatch } from 'js/reducers/storeAdmin';
import { requestIsLoading } from 'ecto-common/lib/utils/requestStatus';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { NodeV2ResponseModel } from 'ecto-common/lib/API/APIGen';
import { useQueryClient } from '@tanstack/react-query';
import { useStore } from 'zustand';
import { nodeTreeStore } from 'ecto-common/lib/LocationTreeView/NodeTreeStore';

interface InstantiateEmptyBuildingProps {
  location: NodeV2ResponseModel;
  isOpen: boolean;
  onModalClose: () => void;
}

const InstantiateEmptyBuilding = ({
  location,
  isOpen,
  onModalClose
}: InstantiateEmptyBuildingProps) => {
  const dispatch = useAdminDispatch();

  const loading = useAdminSelector(
    (state) => state.instantiateEmptyBuilding.loading
  );
  const templateState = useAdminSelector(
    (state) => state.instantiateEmptyBuilding.templateState
  );
  const initDeviceConfigReqState = useAdminSelector(
    (state) => state.equipmentTemplateForm.initDeviceConfigReqState
  );
  const { contextSettings } = useContext(TenantContext);

  const queryClient = useQueryClient();
  const addNodes = useStore(nodeTreeStore, (store) => store.addNodes);
  const allNodes = useStore(nodeTreeStore, (store) => store.allNodes);

  return (
    <ConfigureTemplateEquipmentDialog
      onConfirmClick={() =>
        dispatch(
          InstantiateBuildingActions.performAdd(
            contextSettings,
            location,
            queryClient,
            addNodes,
            allNodes,
            onModalClose
          )
        )
      }
      confirmText={T.common.add}
      confirmIcon={<Icons.Add />}
      onModalClose={() => {
        dispatch(InstantiateBuildingActions.cancel());
        onModalClose();
      }}
      isLoading={requestIsLoading(initDeviceConfigReqState) || loading}
      loadingText={templateState && translationsForTemplateState[templateState]}
      parentLocation={location}
      isOpen={isOpen}
    />
  );
};

export default React.memo(InstantiateEmptyBuilding);
