import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import _ from 'lodash';
import APIGen, {
  NodePropertyResponseModel,
  PropertyValidationType
} from 'ecto-common/lib/API/APIGen';
import DataTable, {
  DataTableColumnProps
} from 'ecto-common/lib/DataTable/DataTable';
import T from 'ecto-common/lib/lang/Language';
import { useSearchParamState } from 'ecto-common/lib/hooks/useDialogState';
import ActionModal from 'ecto-common/lib/Modal/ActionModal/ActionModal';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import Icons from 'ecto-common/lib/Icons/Icons';
import { ModelDefinition } from 'ecto-common/lib/ModelForm/ModelPropType';
import ModelType from 'ecto-common/lib/ModelForm/ModelType';
import ModelForm from 'ecto-common/lib/ModelForm/ModelForm';
import DataTableFooter from 'ecto-common/lib/DataTable/DataTableFooter';
import AddButton from 'ecto-common/lib/Button/AddButton';
import UUID from 'uuidjs';
import ToolbarContentPage from 'ecto-common/lib/ToolbarContentPage/ToolbarContentPage';
import AdminPage from 'js/components/AdminPage';
import { useQueryClient } from '@tanstack/react-query';
import { Y } from 'ecto-common/lib/lang/NodeLocalization';
import Checkbox from 'ecto-common/lib/Checkbox/Checkbox';
import { GenericSelectOption } from 'ecto-common/lib/Select/Select';

const validationOptions: GenericSelectOption<PropertyValidationType>[] = [
  {
    label: Y.properties.validationtypes.none,
    value: PropertyValidationType.None
  },
  {
    label: Y.properties.validationtypes.regex,
    value: PropertyValidationType.Regex
  },
  {
    label: Y.properties.validationtypes.enumlist,
    value: PropertyValidationType.EnumList
  }
];

const propertyModels: ModelDefinition<NodePropertyResponseModel>[] = [
  {
    key: (input) => input.name,
    label: T.common.name,
    modelType: ModelType.TEXT,
    autoFocus: true,
    enabled: (input) => !input.isLocked
  },
  {
    key: (input) => input.description,
    enabled: (input) => !input.isLocked,
    label: T.common.description,
    modelType: ModelType.TEXT
  },
  {
    key: (input) => input.validationType,
    enabled: (input) => !input.isLocked,
    label: Y.properties.validationtype,
    modelType: ModelType.OPTIONS,
    options: validationOptions,
    onDidUpdate(_name, value) {
      switch (value) {
        case PropertyValidationType.None:
          return [
            [
              (input: NodePropertyResponseModel) =>
                input.validationData.enumValues,
              null
            ],
            [
              (input: NodePropertyResponseModel) =>
                input.validationData.regexValue,
              null
            ]
          ];
        case PropertyValidationType.Regex:
          return [
            [
              (input: NodePropertyResponseModel) =>
                input.validationData.enumValues,
              []
            ]
          ];
        case PropertyValidationType.EnumList:
          return [
            [
              (input: NodePropertyResponseModel) =>
                input.validationData.regexValue,
              null
            ]
          ];
        default:
          return undefined;
      }
    }
  },
  {
    key: (input) => input.validationData.regexValue,
    enabled: (input) => !input.isLocked,
    visible: (input) => input.validationType === PropertyValidationType.Regex,
    label: Y.properties.regex,
    modelType: ModelType.TEXT
  },
  {
    key: (input) => input.validationData.enumValues,
    enabled: (input) => !input.isLocked,
    visible: (input) =>
      input.validationType === PropertyValidationType.EnumList,
    options(value: string[]) {
      return _.map(value, (enumValue) => ({
        label: enumValue,
        value: enumValue
      }));
    },
    isMultiOption: true,
    label: Y.properties.enumvalues,
    modelType: ModelType.OPTIONS,
    withCreatableOption: true
  },
  {
    key: (input) => input.localization.sv,
    enabled: (input) => !input.isLocked,
    label: T.format(
      Y.properties.localizationformat,
      T.language.sv.toLowerCase()
    ).join(''),
    modelType: ModelType.TEXT
  },
  {
    key: (input) => input.localization.de,
    enabled: (input) => !input.isLocked,
    label: T.format(
      Y.properties.localizationformat,
      T.language.de.toLowerCase()
    ).join(''),
    modelType: ModelType.TEXT
  }
];

const NodePropertiesList = () => {
  const propertiesQuery = APIGen.NodesV2.getNodeProperties.useQuery();

  const propertiesColumns = useMemo<
    DataTableColumnProps<NodePropertyResponseModel>[]
  >(() => {
    return [
      {
        dataKey: 'isGlobal',
        label: Y.nodes.global,
        minWidth: 70,
        maxWidth: 70,
        align: 'center',
        dataFormatter: (value: boolean) => <Checkbox checked={value} disabled />
      },
      {
        dataKey: 'isLocked',
        label: Y.nodes.locked,
        minWidth: 80,
        maxWidth: 80,
        align: 'center',
        dataFormatter: (value) => (value ? <Icons.Lock /> : null)
      },
      {
        dataKey: 'name',
        label: Y.properties.name,
        linkColumn: true
      },
      {
        dataKey: 'description',
        label: T.common.description
      }
    ];
  }, []);

  const [propertyDetailId, setPropertyDetailId] = useSearchParamState(
    'property-id',
    null
  );

  const onClickRow = (property: NodePropertyResponseModel) => {
    setPropertyDetailId(property.id);
  };

  const [editProperty, setEditProperty] =
    useState<NodePropertyResponseModel | null>(null);

  if (propertyDetailId != null && editProperty == null) {
    const specificProperty = propertiesQuery.data?.find(
      (x) => x.id === propertyDetailId
    );

    if (specificProperty != null) {
      setEditProperty(_.cloneDeep(specificProperty));
    }
  }

  const isEditingTrait = propertyDetailId != null;

  const queryClient = useQueryClient();
  const { contextSettings } = useContext(TenantContext);
  const saveMutation = APIGen.NodesV2.addOrUpdateNodeProperties.useMutation({
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: APIGen.NodesV2.getNodeProperties.path(contextSettings)
      });

      setPropertyDetailId(null);
      setEditProperty(null);
    }
  });
  const save = useCallback(() => {
    saveMutation.mutate({
      properties: [editProperty]
    });
  }, [editProperty, saveMutation]);

  const addProperty = useCallback(() => {
    setEditProperty({
      id: UUID.generate(),
      name: 'New property',
      description: null,
      isLocked: false,
      isGlobal: false,
      validationType: PropertyValidationType.None
    });
  }, []);

  const sortedData = useMemo(() => {
    return _.orderBy(
      propertiesQuery.data,
      ['isGlobal', 'name'],
      ['desc', 'asc']
    );
  }, [propertiesQuery.data]);

  return (
    <>
      <DataTable
        columns={propertiesColumns}
        data={sortedData}
        isLoading={propertiesQuery.isLoading}
        onClickRow={onClickRow}
      />
      <DataTableFooter alignRight>
        <AddButton onClick={addProperty}>{Y.properties.addproperty}</AddButton>
      </DataTableFooter>

      <ActionModal
        isOpen={propertyDetailId != null || editProperty != null}
        onModalClose={() => {
          setPropertyDetailId(null);
          setEditProperty(null);
        }}
        title={
          isEditingTrait ? Y.properties.editproperty : Y.properties.addproperty
        }
        onConfirmClick={save}
        headerIcon={Icons.Edit}
        disableActionButton={editProperty?.isLocked}
        isLoading={propertiesQuery.isLoading || saveMutation.isPending}
        actionText={
          <>
            <Icons.Save />
            {T.common.save}
          </>
        }
      >
        {editProperty && (
          <>
            <ModelForm
              input={editProperty}
              setInput={setEditProperty}
              models={propertyModels}
            />
          </>
        )}
      </ActionModal>
    </>
  );
};

const NodeProperties = () => {
  useEffect(() => {
    document.title = Y.nodes.nodeproperties;
  }, []);

  return (
    <AdminPage
      content={
        <ToolbarContentPage
          showLocationPicker={false}
          title={Y.nodes.nodeproperties}
        >
          <NodePropertiesList />
        </ToolbarContentPage>
      }
    />
  );
};

export default React.memo(NodeProperties);
