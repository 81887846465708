import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import classNames from 'classnames';

import styles from './LocationSidebar.module.css';
import T from 'ecto-common/lib/lang/Language';
import { NavLink } from 'react-router-dom';

import { ArrowIcon } from 'ecto-common/lib/Icon';
import { KEY_CODE_ENTER } from 'ecto-common/lib/constants';
import Icons from 'ecto-common/lib/Icons/Icons';
import UserSettings from 'ecto-common/lib/LocationSidebar/UserSettings';
import { useLogout } from 'ecto-common/lib/hooks/useAuthentication';
import Spinner from 'ecto-common/lib/Spinner/Spinner';
import Select, { GenericSelectOption } from 'ecto-common/lib/Select/Select';

import _ from 'lodash';
import TenantContext from 'ecto-common/lib/hooks/TenantContext';
import { clearReduxState } from 'ecto-common/lib/actions/clearReduxState';
import UserContext from 'ecto-common/lib/hooks/UserContext';
import {
  useCommonSelector,
  useCommonDispatch
} from 'ecto-common/lib/reducers/storeCommon';
import { PageNavLink, PageNavLinkSubLink } from 'ecto-common/lib/Page/Page';
import { NodeParams } from 'ecto-common/lib/utils/locationPathUtils';
import { nodeTreeStore } from 'ecto-common/lib/LocationTreeView/NodeTreeStore';
import { useStore } from 'zustand';
import { useQueryClient } from '@tanstack/react-query';
import { resetAllNodeCaches } from 'ecto-common/lib/hooks/useCurrentNode';

interface LocationSidebarProps {
  signedIn: boolean;
  onToggleSidebar?(): void;
  navLinks?: PageNavLink[];
  isLoading?: boolean;
}

const LocationSidebar = ({
  signedIn,
  onToggleSidebar,
  navLinks = [],
  isLoading = false
}: LocationSidebarProps) => {
  const sideBarExpanded = useCommonSelector(
    (state) => state.general.sideBarExpanded
  );

  const nodeId = useCommonSelector((state) => state.general.nodeId);
  const { userData } = useContext(UserContext);
  const { tenantId, tenants, isLoadingTenants, tenantsFailedToLoad } =
    useContext(TenantContext);

  const [showingAccountDialog, setShowAccountDialog] = useState(false);
  const logout = useLogout();

  const dispatch = useCommonDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    setShowAccountDialog(false);
  }, [location.key]);

  const expandMenu = useCallback(
    (
      mouseEvent:
        | React.MouseEvent<HTMLDivElement>
        | React.KeyboardEvent<HTMLDivElement>
    ) => {
      // TODO: Temporarily disabled
      if ((mouseEvent.target as HTMLElement).tagName !== 'A') {
        mouseEvent.preventDefault();
        onToggleSidebar();
      }
    },
    [onToggleSidebar]
  );

  const onKeyUp = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      if (e.keyCode === KEY_CODE_ENTER) {
        expandMenu(e);
      }
    },
    [expandMenu]
  );

  const params = useParams<NodeParams>();

  // LocationSidebar can sometimes be used in a context where we do not have access to the tree store (when used as loading placeholder)
  // In that case, reference an empty tree store to avoid errors
  const rootLevelNodes = useStore(
    nodeTreeStore,
    (store) => store.rootLevelNodes
  );

  // This is because there is a slight delay in updating the redux state for nodeId/grid.
  // When the location changes there is a small window where the nav links use the old nodeId / grid from
  // redux and the active state does not match the URL properly, causing a blinking frame. If we fetch it
  // through params it matches exactly what is in the current URL. We resort to the redux state for situations
  // where the nodeId and grid are not in the URL.
  const _nodeId = params.nodeId ?? nodeId ?? rootLevelNodes[0]?.nodeId;

  const navClassNames = classNames(
    styles.globalLink,
    sideBarExpanded && styles.expanded
  );
  const toggleAccountDialog = useCallback(
    (e: React.MouseEvent<HTMLDivElement>) => {
      e.preventDefault();
      setShowAccountDialog((oldState) => !oldState);
    },
    [setShowAccountDialog]
  );

  const onUserMenuEnterClick = useCallback(
    (event: React.KeyboardEvent<HTMLDivElement>) => {
      if (event.keyCode === KEY_CODE_ENTER) {
        setShowAccountDialog(true);
      }
    },
    []
  );

  const onEnterClick = useCallback(
    (event: React.KeyboardEvent<HTMLAnchorElement>) => {
      if (event.keyCode === KEY_CODE_ENTER) {
        setShowAccountDialog(false);
      }
    },
    []
  );

  const tenantOptions = useMemo(() => {
    return tenants.map((tenant) => ({
      label: tenant.name,
      value: tenant.id
    }));
  }, [tenants]);

  const selectedTenantOption = _.find(tenantOptions, ['value', tenantId]);
  const clearAllNodes = useStore(nodeTreeStore, (store) => store.clearAllNodes);
  const queryClient = useQueryClient();
  const { contextSettings } = useContext(TenantContext);

  const changeTenant = useCallback(
    (newValue: GenericSelectOption<string>) => {
      if (newValue.value === tenantId) {
        return;
      }

      resetAllNodeCaches(queryClient, contextSettings);
      clearAllNodes();
      dispatch(clearReduxState());
      navigate(`/${newValue.value}`);
    },
    [tenantId, queryClient, contextSettings, clearAllNodes, dispatch, navigate]
  );

  if (!signedIn) {
    return <></>;
  }

  return (
    <div className={styles.sideContent}>
      <nav
        className={classNames(
          styles.sidebar,
          sideBarExpanded && styles.expanded
        )}
      >
        <div
          className={classNames(
            styles.locationInfo,
            sideBarExpanded && styles.expanded
          )}
          onClick={expandMenu}
        >
          {sideBarExpanded && (
            <div className={styles.logo}>{T.navbar.title}</div>
          )}
          {
            <div
              className={classNames(
                styles.locationButton,
                sideBarExpanded && styles.expanded
              )}
              tabIndex={1}
              onKeyUp={onKeyUp}
            >
              <div
                className={classNames(
                  styles.locationArrow,
                  sideBarExpanded && styles.expanded
                )}
              >
                <ArrowIcon data-test="sidebar-toggle" />
              </div>
            </div>
          }
        </div>

        {sideBarExpanded && (
          <div className={styles.tenantsSection}>
            <div className={styles.navTitle}>{T.tenants.title}</div>
            <div className={styles.tenantSelector}>
              <Select
                className={styles.tenantSelectorComponent}
                options={tenantOptions}
                value={selectedTenantOption}
                onChange={changeTenant}
                isLoading={isLoadingTenants}
                isDisabled={isLoadingTenants || tenantsFailedToLoad}
              />
            </div>
          </div>
        )}

        {isLoading && (
          <div className={styles.loadingSection}>
            {' '}
            <Spinner />{' '}
          </div>
        )}

        {!isLoading &&
          navLinks.map((navLinkSection) => {
            if (navLinkSection.links.length === 0) {
              return null;
            }

            return (
              <div key={navLinkSection.title} className={styles.navLinkSection}>
                {navLinkSection.title && (
                  <div className={styles.navTitle}>
                    {sideBarExpanded && navLinkSection.title}
                  </div>
                )}
                {navLinkSection.links.map((navlink: PageNavLinkSubLink) => (
                  <NavLink
                    tabIndex={sideBarExpanded ? undefined : -1}
                    key={navlink.path(tenantId, _nodeId)}
                    className={({ isActive }) =>
                      classNames(
                        navClassNames,
                        isActive && styles.globalLinkActive
                      )
                    }
                    data-test={navLinkSection.title + ' ' + navlink.name}
                    to={navlink.path(tenantId, _nodeId)}
                    onKeyDown={onEnterClick}
                  >
                    <div className={styles.linkContainer}>
                      <div
                        className={classNames(
                          styles.icon,
                          sideBarExpanded && styles.expanded
                        )}
                      >
                        {navlink.icon}
                      </div>
                      {sideBarExpanded && navlink.name}
                    </div>
                  </NavLink>
                ))}
              </div>
            );
          })}

        <div className={styles.expandingSpace} />

        <div className={styles.bottomSection}>
          <div
            tabIndex={0}
            className={classNames(
              styles.globalLink,
              styles.settingsLink,
              sideBarExpanded && styles.expanded,
              styles.globalAccountLink,
              showingAccountDialog && styles.globalAccountLinkActive
            )}
            onClick={toggleAccountDialog}
            data-test="showAccountDialog"
            onKeyDown={onUserMenuEnterClick}
          >
            <div
              className={classNames(
                styles.icon,
                sideBarExpanded && styles.expanded
              )}
            >
              {' '}
              {<Icons.User />}
            </div>
            {sideBarExpanded && (userData?.displayName ?? T.common.loading)}
            <div className={styles.expandingSpace} />
            {sideBarExpanded && <Icons.MenuRightArrow />}
          </div>

          <div>
            <NavLink
              tabIndex={sideBarExpanded ? undefined : -1}
              key={'helpPage'}
              className={({ isActive }) =>
                classNames(navClassNames, isActive && styles.globalLinkActive)
              }
              to={'/' + tenantId + '/help'}
              data-test={'Help'}
            >
              <div className={styles.linkContainer}>
                <div
                  className={classNames(
                    styles.icon,
                    sideBarExpanded && styles.expanded
                  )}
                >
                  {' '}
                  {<Icons.Question />}
                </div>
                {sideBarExpanded && T.location.page.help}
              </div>
            </NavLink>
          </div>
        </div>
      </nav>

      {showingAccountDialog && (
        <div
          tabIndex={1}
          className={classNames(
            styles.accountScreen,
            sideBarExpanded && styles.expanded
          )}
        >
          <UserSettings onLogoutClicked={logout} />
        </div>
      )}
    </div>
  );
};

export default React.memo(LocationSidebar);
